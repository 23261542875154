import * as React from 'react';
import Layout from '../components/Layout/Layout';
import * as styles from './index.module.scss';
// import { StaticImage } from 'gatsby-plugin-image';
import { IconContext } from 'react-icons';
import { SiFastly } from 'react-icons/si';
import { FaTools, FaWallet } from 'react-icons/fa';
import { Heading } from '../components/Heading/Heading';
import { Text } from '../components/Text/Text';
import Seo from '../components/Seo/Seo';
import clsx from 'clsx';
import { HiOutlineChevronDoubleRight as Chevron } from 'react-icons/hi';
import { Link } from 'gatsby';
import { BsArrowRightCircle as ArrowButton } from 'react-icons/bs';

const IndexPage = () => {
  return (
    <Layout>
      <Seo
        title={`Déboucheur de canalisation professionnel | Lège-Cap-Ferret (33236 /33950) | Fabrice Anton, Bassin Pro Service`}
        description={`Une entreprise expérimentée dans le débouchage de canalisations et l'hydrocurage qui garantie une intervention sérieuse et professionnelle. Disponible 24h/24 & 7j/7. `}
        lang={'fr'}
      />

      <div className='text-center'>
        <div className={styles.hero}>
          <div
            className={clsx({
              [`container`]: true,
            })}
          >
            {/* ============== */}
            <section
              className={clsx({
                [styles.banner]: true,
                ['row']: true,
              })}
            >
              <div className='col-12'>
                <Heading
                  tagName={'h2'}
                  styleType={'h2'}
                  textAlignment={'center'}
                  textColour={'white'}
                >
                  À PROPOS
                </Heading>
              </div>
            </section>
            <div className='row'>
              <div className='col-12'>
                <div className={styles.breadcrumb}>
                  <Link to='/' className={styles.linkBreadcrumb}>
                    BASSIN PRO SERVICES
                  </Link>
                  <span className={styles.chevron}>
                    <IconContext.Provider
                      value={{ size: '1em', color: '#15acdb' }}
                    >
                      <Chevron title='Icon intervention débouchage des conduits souterrains' />
                    </IconContext.Provider>
                  </span>
                  <span className={styles.textBreadcrumb}>À PROPOS</span>
                </div>
              </div>
            </div>
          </div>
          {/* <StaticImage
            src='../images/hero-image-lavabo-2.jpg'
            alt='Un systeme de canalisation entretenu par un professionnel Fabrice Anton'
          /> */}
        </div>
      </div>
      <div className='container'>
        {/* ============== */}
        <section className='row justify-content-center' id='a-propos'>
          {/* <div className='col-12 col-lg-4'>
            <StaticImage
              src='../images/shot-one.png'
              alt='Un systeme de canalisation entretenu par un professionnel Fabrice Anton'
            />
          </div> */}
          <div className='col-12 col-xlL-10'>
            <header className={styles.sectionHeader}>
              <Heading
                tagName={'h2'}
                styleType={'h4'}
                textAlignment={'start'}
                text={'Professionnels et expérimentés'}
                textColour={'secondary'}
              />
              <Heading
                tagName={'h3'}
                styleType={'h2'}
                textAlignment={'start'}
                text={`La garantie d'une intervention technique sérieuse`}
                textColour={'primary'}
              />
            </header>
            <Text tagName={'p'} styleType={'bodyRegular'}>
              Bassin Pro Services, c'est avant tout l'œil d'un professionnel,
              Fabrice Anton, un expert plombier et canalisateur pourvu d'une
              riche expérience, ayant opéré au nom de nombreuses entreprises et
              pour de nombreux clients et collectivités.
            </Text>
            <Text tagName={'p'} styleType={'bodyRegular'}>
              Pendant plus d'une quinzaine d'années, nous avons été chargés de
              la création, du remplacement et de la réhabilitation de réseaux
              humides, de gaz et d'eau potable pour les Chantiers d'Aquitaine.
              Nous avons développé une expertise professionnelle de la
              plomberie, des canalisations et des réseaux d'assainissement en
              général. Cette expérience acquise sur de nombreux chantiers de
              tailles variées, vous assure ainsi aujourd'hui le rétablissement
              de l'évacuation défaillante de votre réseau.
            </Text>
          </div>
        </section>
      </div>
      {/* ============== */}
      <div className={styles.heroPage} id='offres'>
        <div className='container'>
          <div className='row  text-center justify-content-center'>
            <div className='col-12 mb-4 col-md-8'>
              <header className={styles.sectionHeader}>
                <Heading
                  tagName={'h2'}
                  styleType={'h4'}
                  textAlignment={'center'}
                  text={'Notre offre'}
                  textColour={'tertiary'}
                />
                <Heading
                  tagName={'h3'}
                  styleType={'h2'}
                  textAlignment={'center'}
                  text={`Une intervention rapide et de qualité`}
                  textColour={'white'}
                />
              </header>
              <Text tagName={'p'} styleType={'h5'}>
                Actif sur la région du Nord Bassin au Sud Médoc, nous sommes
                disponibles pour intervenir sur vos évacuations d'Audenge à
                Lacanau, jusqu'au Cap-Ferret.
              </Text>
            </div>
          </div>
          <div className='row  text-center'>
            <div className='col-12 col-md-4 mb-3 mb-md-0'>
              <div className={styles.badgeWrapper}>
                <div className={styles.badge}>
                  <IconContext.Provider
                    value={{ size: '2.5em', color: '#0277bd' }}
                  >
                    <SiFastly title='Icon intervention débouchage canalisation rapide' />
                  </IconContext.Provider>
                </div>
              </div>
              <Heading
                tagName={'h4'}
                styleType={'h4'}
                textAlignment={'center'}
                text={`Intervention rapide`}
                textColour={'tertiary'}
              />
              <Text tagName={'h5'} fontWeight={'bold'} styleType={'h5'}>
                Chez vous dès que possible
              </Text>
            </div>

            <div className='col-12 col-md-4 mb-3 mb-md-0'>
              <div className={styles.badgeWrapper}>
                <div className={styles.badge}>
                  <IconContext.Provider
                    value={{ size: '2.5em', color: '#0277bd' }}
                  >
                    <FaTools title='Icon Fabrice Anton expert en assainissement de canalisations' />
                  </IconContext.Provider>
                </div>
              </div>
              <Heading
                tagName={'h4'}
                styleType={'h4'}
                textAlignment={'center'}
                text={`Un expert pour vous`}
                textColour={'tertiary'}
              />
              <Text tagName={'h5'} fontWeight={'bold'} styleType={'h5'}>
                Un hydrocureur à très haute pression
              </Text>
            </div>

            <div className='col-12 col-md-4 mb-3 mb-md-0'>
              <div className={styles.badgeWrapper}>
                <div className={styles.badge}>
                  <IconContext.Provider
                    value={{ size: '2.5em', color: '#0277bd' }}
                  >
                    <FaWallet title='Icon Fabrice Anton expert des conduits souterrains de plomberie' />
                  </IconContext.Provider>
                </div>
              </div>
              <Heading
                tagName={'h4'}
                styleType={'h4'}
                textAlignment={'center'}
                text={`Un coût juste`}
                textColour={'tertiary'}
              />
              <Text tagName={'h5'} fontWeight={'bold'} styleType={'h5'}>
                Avec un expert plombier
              </Text>
            </div>
          </div>
        </div>
      </div>
      {/* ============== */}
      <div className='container'>
        <section className='row justify-content-center'>
          <div className='col-12 col-xlL-10'>
            <header className={styles.sectionHeader}>
              <Heading
                tagName={'h2'}
                styleType={'h4'}
                textAlignment={'start'}
                text={'Le débouchage de vos canalisations à tout moment'}
                textColour={'secondary'}
              />
              <Heading
                tagName={'h3'}
                styleType={'h2'}
                textAlignment={'start'}
                text={`Disponible 24h/24 & 7j/7`}
                textColour={'primary'}
              />
            </header>
            <Text tagName={'p'} styleType={'bodyRegular'}>
              Le dysfonctionnement de l'évacuation d'eaux usées est un problème
              handicapant que nous comprenons. Dès lors, nous venons à votre
              demande en vous assurant la restitution du fonctionnement optimal
              de vos évacuations en permanence.
            </Text>
            <Text tagName={'p'} styleType={'bodyRegular'}>
              Nous restons disponibles par téléphone tous les jours de l'année,
              à n'importe quelle heure de la journée ou de la nuit.
            </Text>
            <Link to='/contact' className={styles.linkButton}>
              <span className={styles.label}>Nous contacter</span>
              <IconContext.Provider value={{ size: '1.5em', color: '#FFF' }}>
                <ArrowButton title='Icon intervention débouchage des conduits souterrains' />
              </IconContext.Provider>
            </Link>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default IndexPage;
